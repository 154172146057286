<template>
    <v-sheet>
        <section id="hero-section">
            <hero-section></hero-section>
        </section>
        <section id="what-is">
            <what-is></what-is>
        </section>
        <section>
            <benefits-video link="https://www.youtube.com/embed/s4jGDqJCfSM"></benefits-video>
        </section>
        <section>
            <what-we-do></what-we-do>
        </section>
        <section>
            <how-it-works></how-it-works>
        </section>
        <section id="benefits">
            <benefits-card></benefits-card>
        </section>
        <section>
            <features-card></features-card>
        </section>
        <section id="client-testimonials">
            <client-testimonials></client-testimonials>
        </section>
        <section>
            <mission-card></mission-card>
        </section>
        <section id="call-to-action">
            <try-now></try-now>
        </section>
        <section id="footer">
            <app-footer></app-footer>
        </section>
    </v-sheet>
</template>

<script setup>
  import HeroSection from '@/components/service-providers/HeroSection.vue';
  import WhatIs from '@/components/welcome/WhatIs.vue';
  import WhatWeDo from '@/components/service-providers/WhatWeDo.vue';
  import BenefitsCard from '@/components/business-buyers/BenefitsCard.vue';
  import BenefitsVideo from '@/components/BenefitsVideo.vue';
  import HowItWorks from '@/components/service-providers/HowItWorks.vue';
  import ClientTestimonials from '@/components/ClientTestimonials.vue';
  import MissionCard from '@/components/MissionCard.vue';
  import FeaturesCard from '@/components/business-buyers/FeaturesCard.vue';
  import TryNow from '@/components/business-buyers/TryNow.vue';
  //import RequestDemo from '@/components/business-buyers/RequestDemo.vue';
  import AppFooter from '@/components/AppFooter.vue';
</script>

<script>
export default {
    name: "NewTechTalentMarketplace",
    data: () => ({
       expand: true
    }),
    computed: {
        path() {
            return this.$route.fullPath;
        }
    }
};
</script>

<style scoped>

#hero-section {
    background-size: 100vw 60vh;
    background-color: black;
    color: white;
}

#what-is {
    margin-top: 90px;
    margin-bottom: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#benefits {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#call-to-action {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
}

#footer {
    height: 10vh;
}
</style>