<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" class="text-center">
                
                <p class="text-h5">
                    {{ $t('SOFTWARE_VENDORS') }}
            </p>
        </v-col>
    </v-row>
                
                <v-row justify="center">
                    <v-col cols="auto" v-for="(item, i) in vendors" :key="i">
                        <img :src="item.image" :alt="item.name" class="logo"/>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col cols="12" class="text-center">

                <p class="font-weight-light">
                {{ $t('LOGO_DISCLAIMER') }}

                </p>
                </v-col>
                </v-row>
    </v-container>
</template>
 
<script>
export default {
    name: 'HowItWorks',
    data() {
        return {
            vendors: [
                { name : "Red Hat", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://www.redhat.com&size=64"},
                { name : "Microsoft", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://azure.microsoft.com&size=64"},
                { name : "Service Now", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://www.servicenow.com&size=64"},
                { name : "Oracle", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://www.oracle.com&size=64"},
                { name : "AWS", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://aws.amazon.com&size=64"},
                { name : "Google", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://cloud.google.com/&size=64"},
                { name : "Mulesoft", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.mulesoft.com/&size=64"},
                { name : "Salesforce", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.salesforce.com/&size=64"},
                { name : "Hubspot", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.hubspot.com/&size=64"},
                { name : "Cisco", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.cisco.com/&size=64"},
                { name : "Workato", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.workato.com/&size=64"},
                { name : "Snowflake", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.snowflake.com&size=64"},
                { name : "Software AG", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.softwareag.com&size=64"},
                { name : "Workday", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.workday.com&size=64"},
                { name : "Netsuite", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.netsuite.com&size=64"},
                { name : "Adobe", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://business.adobe.com/&size=64"},
                { name : "Elastic", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=http://www.elastic.co&size=64"},
                { name : "Suse", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.suse.com/&size=64"},
                { name : "Dynatrace", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.dynatrace.com/&size=64"},
                { name : "Celonis", image: "https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.celonis.com/&size=64"},
            ]
        }
    }
}
</script>
 
<style scoped>
#container {
    width: 1000px;
    overflow: hidden;
    background: white;
}

.banner {
    height: 100px;
    width: 100%;
    margin-bottom: 80px;
    position: absolute;
    -webkit-animation: bannermove 30s linear infinite;
       -moz-animation: bannermove 30s linear infinite;
        -ms-animation: bannermove 30s linear infinite;
         -o-animation: bannermove 30s linear infinite;
            animation: bannermove 30s linear infinite;
}

.logo {
    height: 32px;
    width: 32px;
    margin-left: 5px;
    border-radius: 14px;
}


.banner img {
    height: 32px;
    width: 32px;
    margin-left: 5px;
    border-radius: 14px;
}


@keyframes bannermove {
 0% {
    margin-left: 0px;
 }
 100% {
    margin-left: -100;
 }
 
}
 
@-moz-keyframes bannermove {
 0% {
   margin-left: 0px;
 }
 100% {
   margin-left: -100;
 }
 
}
 
@-webkit-keyframes bannermove {
 0% {
   margin-left: 0px;
 }
 100% {
   margin-left: -100;
 }
 
}
 
@-ms-keyframes bannermove {
 0% {
   margin-left: 0px;
 }
 100% {
   margin-left: -100;
 }
 
}
 
@-o-keyframes bannermove {
 0% {
   margin-left: 0px;
 }
 100% {
   margin-left: -100;
 }
 
}


#scroll-container {
    width: 1000px;
    overflow: hidden;
    margin: 50px auto;
    background: white;
}

.carousel {
    height: 233px;
    width: 14450px;
    margin-bottom: 80px;
    animation: scroll-horizontal 40s linear infinite;
}

.carousel img {
    height: 32px;
    width: 32px;
    margin-left: 5px;
    border-radius: 14px;
}

.carousel:hover,
.carousel:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

@keyframes scroll-horizontal {
    0% {
        left: 100%
    }

    100% {
        left: -100%
    }
}



</style>