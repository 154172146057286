<template>
    <v-sheet variant="flat" :color="backgroundColor" class="benefits py-16">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <p class="text-h3">Our Service Providers offer the following expert solutions for your Business</p>
                </v-col>
            </v-row>
            <br>
            <v-divider></v-divider>
            <br>
            <v-container>
            <v-row>
                <v-col cols="12">
                    <p class="text-h5">
                                    Domains
                                </p>
                </v-col>
            </v-row>
                <v-row>
                <v-col cols="12" md="6" lg="4" xl="4" v-for="(domain, i) in domains" :key="i">
                    <v-card variant="flat" class="fill-height d-flex flex-column rounded-lg" color="indigo darken-2" min-width="300" >
                        <v-card-text class="text-left">
                            <v-list-item>
                                <template v-slot:prepend>
                                    <v-avatar color="white" size="32">
                                        <v-icon color="blue" :icon="domain.icon"></v-icon>
                                    </v-avatar>

                                </template>
                                <v-list-item-title v-text="domain.text"></v-list-item-title>
                            </v-list-item>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <p class="text-h5">
                        Offerings
                    </p>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col sm="12" md="6" lg="4" xl="4" v-for="(service, i) in services" :key="i">
                    <v-card variant="flat" class="fill-height d-flex flex-column rounded-lg" color="#EE8254" min-width="300" min-height="100" max-height="200">
                        <v-card-text>

                            <v-list-item>
                                <v-list-item-title>{{ service.text }}</v-list-item-title>
                                <v-list-item-subtitle>{{ service.description }}</v-list-item-subtitle>
                            </v-list-item>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center">
                <software-vendors></software-vendors>
            </v-row>
        </v-container>

        </v-container>
    </v-sheet>
</template>

<script setup>
import SoftwareVendors from '@/components/SoftwareVendors.vue';
</script>

<script>
export default {
    name: 'ServiceProviderSolutions',
    props: ['backgroundColor'],
    data() {
        return {
            services: [
                {
                    text: "Vendor Assessment Services",
                    description: "Evaluate current and prospective vendors to align with company goals, reduce risks, and enhance partnerships"
                },
                {
                    text: "Project Scope and Definition Services",
                    description: "Define objectives, deliverables, timelines, budget, and resources for an initiative",
                },
                {
                    text: "Workforce Augmentation Services",
                    description: "Add skilled professionals to extend internal team capabilities",
                },
                {
                    text: "Project Based Professional Services",
                    description: "Hire specialized professionals for time-bound project or product work",
                },
                {
                    text: "Managed Operations Services",
                    description: "Outsource full-time technical operations and application or system management",
                }
            ],
            domains: [
                {
                    text: "Artificial Intelligence",
                    icon: "mdi-head-snowflake"
                },
                {
                    text: "Cloud Services",
                    icon: "mdi-cloud-cog"
                },
                {
                    text: "Cybersecurity",
                    icon: "mdi-shield-lock"
                },
                {
                    text: "Business Process Automation",
                    icon: "mdi-vector-square"
                },
                {
                    text: "CRM Implementations",
                    icon: "mdi-account-cash"
                },
                {
                    text: "ERP Implementations",
                    icon: "mdi-finance"
                },
            ]
        }
    },
    
}
</script>
<style scoped>
.benefits {
    text-align: center;
}

.benefit {
    padding: 20px;
    margin: 5px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.benefit-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.benefit-text {
    font-size: 14px;
    color: #666;
}
</style>