export default {
  "HEADLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecting Technology Projects with Expert Consultants"])},
  "SUB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your next"])},
  "HOW_IT_WORKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
  "FOR_YOU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 0.75em;'>Your <span style='background-color: #2979FF;'>B2B</span> technology consultant marketplace<span>"])},
  "WHAT_IS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ConnXions connects supply with demand for B2B IT projects"])},
  "WHAT_WE_DO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission: To transform the way organizations manage their technology service providers by providing a cutting-edge professional services marketplace that streamlines the entire lifecycle of professional services management"])},
  "WHAT_WE_DO2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misson: To empower organizations to make informed decisions, drive innovation, and achieve their goals through effective and efficient partnerships."])},
  "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're dedicated to transforming the way organizations interact with their technology service providers, making it easier, faster, and more efficient to find, engage, and manage the right partners. By providing a seamless and transparent experience, we help you unlock the full potential of your technology investments and drive business success."])},
  "VISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our vision is to be the go-to platform for IT professional services management, empowering organizations to make informed decisions, drive innovation, and achieve their goals through effective and efficient partnerships."])},
  "FOR_BUSINESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Business"])},
  "FOR_SERVICE_PROVIDERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Service Providers"])},
  "BUSINESS_HERO_STATEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 3em;line-height: 70px;'>Connecting Technology Projects to <span style='background-color: #2979FF;'>Expert Consultants</span></span>"])},
  "SERVICE_PROVIDER_HERO_STATEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 3em;line-height: 70px;'>Connecting Experts to <span style='background-color: #EE8254;'>Technology Projects</span></span>"])},
  "PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "CONSULTANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant"])},
  "SOFTWARE_VENDORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Vendors"])},
  "LOGO_DISCLAIMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logos and trademarks displayed on this platform represent certifications held by technology professionals using ConnXions"])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Now"])},
  "REGISTRATION_CONTINUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your interest in Connxions. Please check the email you used to register to continue the sign up process"])},
  "TRY_NOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try For Free"])},
  "offering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerings"])},
  "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Story"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Results"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "requestDemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get A Demo"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "preferredName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Name"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])},
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Email"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "findWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Work"])},
  "findTalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Talent"])},
  "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform Features"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
  "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more..."])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us about your needs"])},
  "searchForTalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for Talent"])},
  "searchForOpportunities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for Opportunities"])},
  "serviceProviderDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I work for a company that specializes in IT professionals for project based work"])},
  "enterpriseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I work for a company that is looking to procure a professional services partner"])},
  "contactUsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's talk about it. What can we answer for you today?"])},
  "demoRequestTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience Innovation For Yourself: Request A Demo Today!"])},
  "perspective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perspective"])},
  "serviceProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Provider"])},
  "enterprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise Clients"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "thanksForYourInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your interest in Connxions. Please check the email you used to register to continue the sign up process"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "getStartedToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Today"])},
  "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
  "signUpDemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up For A Demo"])},
  "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you are looking for was not found or it does not exist"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who we are"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hire Talent"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find A Job"])},
  "diversity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversity, Equality & Inclusion"])},
  "sustainability": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sustainability"]), _normalize(["ESG"])])},
  "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Committment and Approach"])},
  "freeTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up for a free trial"])},
  "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hire Certified IT Professional Services for Enterprise Projects"])},
  "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B SaaS marketplace driven by AI"])},
  "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to verified Skilled Professionals"])},
  "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elastic workforce to scale up your projects"])},
  "subtitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One marketplace, endless connections"])},
  "getStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started with a free trial"])},
  "appbar": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "careers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Careers"])},
    "joinUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Connxions"])},
    "leadership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership"])},
    "executiveTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executive Team"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News and Press Releases"])},
    "newsroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The latest from our newsroom"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Clients"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Their results"])},
    "certifiedTalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified Talent"])},
    "searchJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for Jobs"])},
    "hireTalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hire Talent"])},
    "searchTalent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for Talent"])},
    "staffJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff My Jobs"])},
    "postJob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post a Job"])},
    "getInTouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get In Touch"])},
    "reqeustAContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a contact"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "thankYouMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your request. We will get back to you soon."])},
    "nameRqd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required."])},
    "msgRqd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message is required."])},
    "emailRqd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required."])},
    "emailValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is not valid."])},
    "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your name"])},
    "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email"])},
    "enterValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter valid email"])},
    "connectReqSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your interest. We will get back to you soon."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "clients": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendors"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encompassing a diverse array of enterprises spanning numerous industries, we collaborate with strategic business partners who provide accredited IT professionals specializing in platforms such as RedHat, IBM, VMWare, SAP, Salesforce, AWS, Azure, Google, and a host of other leading technologies."])}
  },
  "careers": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Careers"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep an eye out and return to our website for upcoming possibilities as we continue to grow and expand."])}
  },
  "home": {
    "connxions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONNXIONS"])},
    "getConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GET CONNECTED!"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to ConnXions, the digital marketplace meticulously designed and tailored for our partners and customers, enabling the delivery of certified professionals to enterprise clients across the globe."])},
    "box1": {
      "tagline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to certified IT professionals"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified, trusted resources delivering enterprise resources"])},
      "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ConnXions marketplace provides access to certified and endorsed IT professionals sourced from our extensive global network of business partners. These partners are integral to the software vendors ecosystem, utilizing certified professionals within their workforce for enterprise projects."])}
    },
    "box2": {
      "tagline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivering the elastic workforce to scale"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprises require on demand, flexible highly skilled resources"])},
      "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprises have project-based work where they can determine their future workforce requirements, as well as immediate needs to supplement their ongoing projects. As a result, ConnXions' marketplace supports both use cases in order to provide ultimate flexibility with certified resources."])}
    },
    "box3": {
      "tagline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Marketplace delivering endless connections"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Marketplace delivering endless connections"])},
      "subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ConnXions marketplace leverages the certified talent of our trusted partners to fulfill enterprise demand, following a strict set of criteria to ensure consistent quality. In order to meet the needs of our enterprise customers, we provide a flexible and skilled workforce that is only accessible from our approved partners."])}
    }
  }
}