<template>
    <v-container class="bg-transparent-variant mt-5 mb-5">
        <v-row justify="center">
            <v-col cols="auto">
                <v-card variant="outlined" class="card" width=400 min-width=300 min-height=400
                    :image="require('@/assets/images/opportunity.png')" theme="dark" color="white">
                    <v-card-text>
                                <p class="text-h6">For Service Providers</p>
                                <p class="text-h3">Search for</p>
                                <p class="text-h3 font-weight-bold">your Next Opportunity</p>
                                <!--
                            <p class="text-h6">
                                Service Providers, come find your next enterprise customers from the ConnXions platform. Increase your services professionals' utilization rate and your company's profitability. ConnXions makes it easy for you to find projects worldwide with top-tier companies.
                            </p>-->
                    </v-card-text>
                    <v-card-actions class="ml-3">
                        <v-btn variant="outlined" size="large" rounded="xl" color="white"
                                        class="pl-10 pr-10" to="/evolved-tech-project-access">
                                        {{ $t('learnMore') }}
                                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="auto">
                <v-card variant="outlined" class="card" width=400 min-width=300 min-height=400 height="100%"
                    :image="require('@/assets/images/talent.png')" theme="dark" color="white">
                    <v-card-text>
                        <p class="text-h6">For Business</p>
                        <p class="text-h3">Search for</p>
                        <p class="text-h3 font-weight-bold">the Right<br>Talent</p>
                        <!--
                            <p class="text-h6">
                                ConnXions' intuitive, AI-driven talent search platform connects you with certified IT professionals globally. Start exploring our vast network of talent and discover the perfect fit for your needs.
                            </p>-->
                    </v-card-text>
                    <v-card-actions class="ml-3">
                        <v-btn variant="outlined" size="large" rounded="xl" color="white" class="pl-10 pr-10"
                            to="/new-tech-talent-marketplace">
                            {{ $t('learnMore') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>

.card {
    height: 100%;
  }

</style>

<script>
export default {
    name: "SectionThree",
    computed: {
        baseUrl() {
            return process.env.VUE_APP_CONSOLE_BASE_URL;
        }
    }
};
</script>