<template>
    <v-sheet>
        <section id="hero-section">
            <hero-section></hero-section>
        </section>
        <section id="section2">
            <what-is></what-is>
        </section>
        <v-divider></v-divider>
        <section id="section2">
            <benefits-video link="https://www.youtube.com/embed/GfaFFsDrGY8"></benefits-video>
        </section>
        <v-divider></v-divider>
        <section id="section2">
            <section-two></section-two>
        </section>
        <section id="service-provider-offerings">
            <service-provider-solutions backgroundColor="#FFFFFF"></service-provider-solutions>
        </section>
        <section id="section3">
            <section-three></section-three>
        </section>
        <section>
            <mission-card></mission-card>
        </section>
        <section>
            <client-testimonials></client-testimonials>
        </section>
        <section id="call-to-action">
            <try-now></try-now>
        </section>
        <!--
        <section id="section4">
            <our-story></our-story>
        </section>
        -->
        <section id="footer">
            <app-footer></app-footer>
        </section>
    </v-sheet>
</template>

<script setup>
  import HeroSection from '@/components/welcome/HeroSection.vue';
  import WhatIs from '@/components/welcome/WhatIs.vue';
  import BenefitsVideo from '@/components/BenefitsVideo.vue';
  import ServiceProviderSolutions from '@/components/business-buyers/ServiceProviderSolutions.vue';
  import SectionTwo from '@/components/SectionTwo.vue';
  import SectionThree from '@/components/SectionThree.vue';
  import ClientTestimonials from '@/components/ClientTestimonials.vue';
  import MissionCard from '@/components/MissionCard.vue';
  import TryNow from '@/components/business-buyers/TryNow.vue';
  //import OurBenefits from '@/components/OurBenefits.vue';
  //import OurStory from '@/components/OurJourney.vue';
  //import DiveDeeper from '@/components/DiveDeeper.vue';
  import AppFooter from '@/components/AppFooter.vue';
</script>

<script>
export default {
    name: "WelcomeView",
    data: () => ({
    }),
};
</script>

<style scoped>

#hero-section {
    background-size: 100vw 60vh;
    background-color: black;
}

#software-vendors {
    margin-top: 90px;
    margin-bottom: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#section2 {
    margin-top: 90px;
    margin-bottom: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#section3 {
    background-color: #263238;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

#section4 {
    padding-top: 50px;
    padding-bottom: 50px;
    background: url('@/assets/section2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#call-to-action {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
}

#footer {
    height: 10vh;
}
</style>