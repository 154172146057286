<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="12" lg="6" xl="4" class="text-center">
        <p class="text-h3">
          What is ConnXions?
        </p>
        <p class="text-h5">
          {{ $t('WHAT_IS') }}
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="12" lg="8" xl="6" class="text-center">
      <v-img width="100%" aspect-ratio="16/9" cover src="@/assets/images/how-it-works.gif"></v-img>
    </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
  
</script>
  
<script>
  export default {
    data() {
      return {
        
      }
    },
  }
</script>
  
<style scoped>
  .py-16 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
</style>