<template>
    <v-container class="py-16">
        <v-row>
            <v-col cols="12" class="text-center">
                <p class="text-h4">
                    {{ $t('HOW_IT_WORKS') }}
                </p>
            </v-col>
            <v-col cols="12" lg="6" md="12" class="fill-height d-flex flex-column justify-center align-center">
                <v-card variant="flat">
                    <v-card-text class="text-center">
                        <p class="text-h4">Find <span class="text-blue-accent-3">Projects</span></p>
                        <p class="text-h6">Explore our expansive IT project marketplace to grow your customer base and
                            land new logos. Browse through our project listings to find the perfect fit for your team.
                            Easily assign your team to projects, with all the necessary details included in each
                            posting. We simplify the legal process, making it faster and easier than ever before,
                            allowing you to focus on maximizing your team's utilization.</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6" md="12" class="fill-height d-flex flex-column justify-center align-center">
                <v-card variant="flat">
                    <v-card-text class="text-center">
                        <p class="text-h4">Manage your <span class="text-orange-accent-3">Technology Consultants</span>
                        </p>
                        <p class="text-h6">Effortlessly manage your team of IT professionals with full control. Set
                            their hourly rates, manage their availability, and watch the projects come rolling in. Rest
                            assured that your team's personal information remains confidential, with only their
                            professional experience displayed. Maximize your team's utilization and eliminate idle
                            hours.</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3"></v-divider>
        <v-row justify="center">
            <v-col v-for="(item, index) in steps" :key="index" cols="12" sm="12" md="6" lg="6" xl="3">
                <v-card height=180 variant="flat">
                    <template v-slot:prepend>
                        <v-icon size=64 color="primary" :icon="item.icon"></v-icon>
                    </template>
                    <template v-slot:title>
                        <span class="text-wrap">{{ item.title }}</span>
                    </template>
                    <v-card-text>{{ item.subtitle }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3"></v-divider>
        <SoftwareVendors></SoftwareVendors>

    </v-container>
</template>
 
<script setup>
import SoftwareVendors from '@/components/SoftwareVendors.vue';
</script>
<script>
export default {
    name: 'HowItWorks',
    data() {
        return {
            steps: [
                { icon: "mdi-numeric-1-circle", title: "Access Services Network", subtitle: "Sign-up and access our Professional Services Marketplace on-demand. Discover capabilities, such as: Creating Projects, Searching the “Find Talent” Marketplace and more." },
                { icon: "mdi-numeric-2-circle", title: "Engage Verified Service Providers & Consultants", subtitle: "Find the right skilled Service Providers & Consultants for your Projects, Staffing and Managed Services needs. Our global network provides you connection to on-shore, near-shore, and off-shore Service Providers with the skills and expertise to fit your project needs." },
                { icon: "mdi-numeric-3-circle", title: "Choose the Right Service Provider", subtitle: "Once you find Service Providers & Consultants that may fit your needs, direct message with them over the ConnXions platform. This allows you to receive verified proposals, direct messaging, and interviews to confirm your decision." },
                { icon: "mdi-numeric-4-circle", title: "Contracting Simplified", subtitle: "Your company will contract directly with ConnXions who will ensure high quality work and project oversight. All contract terms and conditions will be passed-down to Service Providers for project transparency and accountability." },
            ]
        }
    }
}
</script>