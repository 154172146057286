<template>
    <v-card variant="flat" color="#263238">
        <v-container class="py-16">
            <v-row justify="center">
                <v-col cols="12" sm="12" md="12" lg="8" xl="6" class="text-center pa-3">
                    <p class="what-we-do-text text-h4">
                        {{ $t('WHAT_WE_DO') }}
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>

<style scoped>

.orange-text {
  color: #EE8254;
}

.what-we-do-text {
  font-size: 24px;
  color: white;
}

</style>