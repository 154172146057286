<template>
   <v-container class="py-16">
    <v-row justify="center">
        <v-col sm="12" md="6" lg="6" xl="4" offset-xl="2">
            <v-card variant="flat" min-width="300" max-width=600>
                    <v-card-text>
                        <p class="text-h3 font-weight-bold orange-text">Transform How You Manage Technology Projects</p>
                    </v-card-text>
                    <v-card-text>
                        <p class="text-h6">Watch this quick video to see how we connect IT professionals with the right projects, helping businesses optimize talent and drive success. More insights await below!</p>
                    </v-card-text>
                    <v-card-text>
                        <p class="text-h6">Need to see more? Schedule an in depth demo with our sales team.</p><br>
                        <v-btn size="large" variant="flat" color="#2979FF" rounded="xl" href="https://calendly.com/customersupport-connxions/demo" target="_blank">Book Now</v-btn>
                    </v-card-text>
                </v-card>
        </v-col>
        <v-col sm="12" md="6" lg="6">
            <v-card variant="flat" min-width="300" max-width=600>
                    <v-card-text class="text-center">
                        <iframe width="100%" height="400" :src="link"
                            frameborder="0"></iframe>
                    </v-card-text>
                </v-card>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'BenefitsVideo',
    props: ['link'],
}
</script>

<style scoped>

.orange-text {
  color: #EE8254;
}

</style>