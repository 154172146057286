<template>
    <v-container class="py-16">
        <v-row>
            <v-col cols="12" class="text-center">
                <p class="text-h4">
                    {{ $t('HOW_IT_WORKS') }}
                </p>
            </v-col>
            <v-col cols="12" lg="6" md="12" class="fill-height d-flex flex-column justify-center align-center">
                <v-card variant="flat">
                    <v-card-text class="text-center">
                        <p class="text-h4">Find <span class="text-blue-accent-3">Talent</span></p>
                        <p class="text-h6">Explore our expansive marketplace of certified technology professionals,
                            thoroughly
                            vetted by us. Easily filter your search by software vendor, certifications, skills,
                            industry, region, and country. We handle the heavy lifting, making the process far smoother
                            than ever before. Each IT consultant's full profile is available, ensuring complete
                            transparency every step of the way.</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6" md="12" class="fill-height d-flex flex-column justify-center align-center">
                <v-card variant="flat">
                    <v-card-text class="text-center">
                        <p class="text-h4">Post a <span class="text-orange-accent-3">Project</span></p>
                        <p class="text-h6">We make posting and managing your technology projects just as simple as
                            finding top talent. Finding top professionals is only half the battle—it should be just as
                            easy to post and manage your projects. You can define your project type, required technology
                            expertise, preferred experience, budget, timeline, and location. Plus, our AI assists you
                            throughout the process, handling mundane tasks no one wants to deal with. It's time to match
                            your project with the right, qualified talent.</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3"></v-divider>
        <v-row justify="center">
            <v-col v-for="(item, index) in steps" :key="index" cols="12" sm="12" md="6" lg="6" xl="3">
                <v-card height=180 variant="flat">
                    <template v-slot:prepend>
                        <v-icon size=64 color="primary" :icon="item.icon"></v-icon>
                    </template>
                    <template v-slot:title>
                        <span class="text-wrap">{{ item.title }}</span>
                    </template>
                    <v-card-text>{{ item.subtitle }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>        
    </v-container>
</template>
 
<script setup>
</script>
<script>
export default {
    name: 'HowItWorks',
    data() {
        return {
            steps: [
                { icon: "mdi-numeric-1-circle", title: "Access Services Network", subtitle: "Sign-up and access our Professional Services Marketplace on-demand. Discover capabilities, such as: Creating Projects, Searching the “Find Talent” Marketplace and more." },
                { icon: "mdi-numeric-2-circle", title: "Engage Verified Service Providers & Consultants", subtitle: "Find the right skilled Service Providers & Consultants for your Projects, Staffing and Managed Services needs. Our global network provides you connection to on-shore, near-shore, and off-shore Service Providers with the skills and expertise to fit your project needs." },
                { icon: "mdi-numeric-3-circle", title: "Choose the Right Service Provider", subtitle: "Once you find Service Providers & Consultants that may fit your needs, direct message with them over the ConnXions platform. This allows you to receive verified proposals, direct messaging, and interviews to confirm your decision." },
                { icon: "mdi-numeric-4-circle", title: "Contracting Simplified", subtitle: "Your company will contract directly with ConnXions who will ensure high quality work and project oversight. All contract terms and conditions will be passed-down to Service Providers for project transparency and accountability." },
            ]
        }
    }
}
</script>